<!-- 考试管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold">人员管理</div>
      <div>
        <div>
          <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 67px"
            class="cinput"
            @click='add(false)'
            >+添加人员</el-button
          >
          <el-radio-group v-model="radio" style="height: 34px" class="cinput" @change='list'>
            <!-- <el-radio-button label="">全部</el-radio-button> -->
            <el-radio-button :label="item.id" v-for='(item,index) in roleList' :key='index'>{{item.name}}</el-radio-button>
            
          </el-radio-group>
        </div>
        <div>
          <el-cascader
          @change='list'
            placeholder="地区选择"
            :options="citys"
            class="cinput ac "
            clearable
            v-model='code'
          ></el-cascader>
          <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="输入姓名"
              class="cinput ac ac2"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
           <!-- <img
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              /> -->
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div>
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="name" label="姓名" align="center" width="180">
        </el-table-column>
        <!-- <el-table-column prop="roleName" align="center" label="角色">
        </el-table-column> -->
         <el-table-column prop="roleName" align="center" label="公域/私域" v-if="radio==3">
            <template slot-scope="scope">
            <div>
              
               <el-link type="danger" v-if="scope.row.brandId==10">公域</el-link>
               <el-link type="success" v-else>私域/{{scope.row.mentorUserName}}</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="地区">
          <template slot-scope="scope">
            <div>
              {{scope.row.provinceName}}-{{scope.row.cityName}}-{{scope.row.areaName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="品牌" v-if="radio==4">
          <template slot-scope="scope">
            <div>
              {{scope.row.brandId==1?'嘉顿肯妮':scope.row.brandId==2?'瑞妮朵拉':scope.row.brandId==3?'素颜':scope.row.brandId==10?'外部学员':''}}
            </div>
          </template>
        </el-table-column>
         <el-table-column prop="roleName" align="center" label="是否购买VIP" v-if="radio==3">
            <template slot-scope="scope">
            <div>
              <el-link type="success" v-if="scope.row.vip==1" :underline="false">已购买</el-link>
              <el-link type="danger" v-else :underline="false">未购买</el-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <el-link type="primary" v-if="scope.row.roleId!=14" :underline="false" style="margin: 0 25px" @click="details(scope.row,true)"
                >查看详情</el-link
              >
              <el-link type="primary" v-if="scope.row.roleId==3" :underline="false" style="margin: 0 25px" @click="setVip(scope.row)"
                >设成VIP</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="805px"
      :before-close="handleClose"
    >
      <div
        style="
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: bold;
        "
      >
        <div class="line"></div>
        <div>添加人员</div>
      </div>
      <div style="display:flex;justify-content:space-between;margin:30px 0">
        <div style="display:flex;align-items:center;width:30%;" class='inp' v-if='value!=13 && !id'>
          <div style='flex-shrink:0;width:80px;text-align:right;font-size:20px;color:#000'>角色：</div>
          <el-select v-model="value" placeholder="请选择" :disabled="isedit">
            <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              
            >
            </el-option>
          </el-select>
        </div>
         <div style="display:flex;align-items:center;width:30%" class='inp' v-if='value==4 '><!--导师-->
          <div style='flex-shrink:0;font-size:20px;color:#000'>品牌:</div>
          <el-select v-model="brandId" placeholder="请选择" :disabled="isdisabled" @change="selectBrand">
            <el-option
              v-for="item in peopleType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="display:flex;align-items:center;width:30%" class='inp' v-if='value==3 || value==4'><!--导师，学员-->
          <div style='flex-shrink:0;font-size:20px;color:#000'>等级:</div>
          <el-select v-model="levelId" placeholder="请选择" :disabled="isedit">
            <el-option
              v-for="item in levelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="display:flex;align-items:center;width:30%" class='inp' v-if='value==3 || value==10'><!--学员，院长-->
          <div style='flex-shrink:0;font-size:20px;color:#000'>门店:</div>
          <el-input v-model="storeName" placeholder="请输入门店" style="width:416px;" :disabled="isedit"></el-input>
          <!-- <el-select v-model="storeId" filterable placeholder="请选择" :disabled="isedit">
            <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
        </div>
      </div>
      <div style="display:flex;align-items:center;margin:30px 0;font-size:20px;color:#000;" v-if='value==4 && !id'>
        <div style="flex-shrink:0;width:100px;text-align:right">职位名称：</div>
        <el-input v-model="positionName" placeholder="请输入职位名称" style="width:416px;" :disabled="isedit"></el-input>
      </div>
      <div style="display:flex;align-items:center;width:50%" class='inp' v-if='value==3 || value==4'><!--导师，学员-->
          <div style='flex-shrink:0;font-size:20px;color:#000'>{{value==3?'导师':'上级导师'}}:</div>
          <el-select v-model="parentUserId" placeholder="请选择" :disabled="isedit" @change="changeMentor">
            <el-option
              v-for="item in teacherList"
              :key="item.userId"
              :label="item.name"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
      <div style="display:flex;align-items:center;margin:30px 0;font-size:20px;color:#000;" >
        <div style="flex-shrink:0;width:80px;text-align:right">姓名：</div>
        <el-input v-model="name" placeholder="请输入姓名" style="width:416px;" :disabled="isedit"></el-input>
      </div>
      <div style="display:flex;align-items:center;margin:30px 0;font-size:20px;color:#000">
        <div style="flex-shrink:0;width:80px;text-align:right">性别：</div>
          <el-radio v-model="sex" label="1" class='inp2' :disabled="isedit">男</el-radio>
          <el-radio v-model="sex" label="0" class='inp2' :disabled="isedit">女</el-radio>
      </div>
      <div style="display:flex;align-items:center;margin:30px 0;font-size:20px;color:#000">
        <div style="flex-shrink:0;width:80px;text-align:right">生日：</div>
         <el-date-picker
         :disabled="isedit"
          v-model="birthday"
          type="date"
          value-format='yyyy-MM-dd'
          placeholder="选择生日">
        </el-date-picker>
      </div>
      <div style="display:flex;align-items:center;margin:30px 0;font-size:20px;color:#000">
        <div style="flex-shrink:0;width:80px;text-align:right">手机号：</div>
        <el-input v-model="phone" placeholder="请输入手机号" style="width:416px" :disabled="isedit"></el-input>
      </div>
      <div style="display:flex;align-items:center;margin:30px 0;font-size:20px;color:#000">
        <div style="flex-shrink:0;width:80px;text-align:right">身份证：</div>
        <el-input v-model="cardNumber" placeholder="请输入身份证号" style="width:416px" :disabled="isedit"></el-input>
      </div>
      <div style="display:flex;align-items:center;margin:30px 0;font-size:20px;color:#000">
        <div style="flex-shrink:0;width:80px;text-align:right">户籍地：</div>
        <el-cascader
            placeholder="地区选择"
            :options="citys2"
            :disabled="isedit"
            clearable
            v-model='code2'
          ></el-cascader>
      </div>
       <div style="display:flex;align-items:center;margin:30px 0;font-size:20px;color:#000" v-if="value==3">
        <div style="flex-shrink:0;width:80px;text-align:right">vip：</div>
        <el-select v-model="vip" placeholder="请选择" :disabled="value==3 && id!=''">
            <el-option
              v-for="item in viplist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              
            >
            </el-option>
          </el-select>
      </div>
      <div style="display:flex;align-items:flex-start;margin:30px 0;font-size:20px;color:#000;display: flex;" v-if="value==4 && id">
        <div style="flex-shrink:0;width:80px;text-align:right" >二维码：</div>
        <div style="display: flex;align-items: center;">
          <img :src="wxcode" alt="" style="width: 200px;height: 200px;" id="imageWrapper">
          <div class="qrcode" ref="qrCodeUrl" id="qrcode"></div>
          <!-- <img :src="qrcode" alt="" style="width: 200px;height: 200px;"> -->
          <div style="color: #666;font-size: 16px;margin-left: 20px;">如需下载，请单机右键另存为</div>
        </div>
        <!-- <el-button type="primary" @click="downs">下载图片</el-button> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit" v-if="!isedit"
          >确 定</el-button>
          <el-button type="primary" @click="isedit=false" v-if="isedit"
          >编辑</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'
import {cityList} from '../../utils/cityCode'
import {levelList,storepageList,usersaveOrUpdate,pageroleList,saveTeacher,getmentorInfo,saveMentor,updateMentor,saveTrainee,updateTrainee,getTeacherList,setUserVip} from '../../apis/index'
export default {
  data() {
    return {
      storeName:'',
      isdisabled:false,
      vip:0,
      viplist:[
        {
          id:0,
          name:'不是vip'
        },
        {
          id:1,
          name:'是vip'
        }
      ],
      newMentorBrandId:'',
      newMentorUserId:'',
      oldMentorBrandId:'',
      oldMentorUserId:'',
      qrcode:'',
      teacherList:[],
      parentUserId:'',
      positionName:'',
      brandId:'',
      peopleType:[
        {
          id:1,
          name:'嘉顿肯妮'
        },
        {
          id:2,
          name:'瑞妮朵拉'
        },
        {
          id:3,
          name:'素颜+'
        },
      ],
      wxcode2:'',
      wxcode:'',
      id:'',
      isedit:false,
      pageIndex:1,
      pageSize:10,
      total:0,
      searchStr:'',
      name:'',
      levelList:[],
      levelId:'',
      roleList:[
        
        {
          id:14,
          name:'讲师'
        }
      ],
      value: 3,
      storeList:[
         {
          id:0,
          name:'暂无门店'
        }
      ],
      storeId:'',
      birthday:'',
      roleId:'',
      citys:'',
      code:[],
      sex:'1',
      phone:'',
      cardNumber:'',
      citys2:'',
      code2:[],
      dialogVisible: false,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      isshow: false,
      isshow2: true,
      currentPage: 1,
      radio: "",
      input: "",
      tableData: [
       
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.input=''
        this.list()
        this.isshow = false;
      }
    },
    value(){
      if(this.value!=''){
        if(this.value==3){
          this.levelId=1//等级一，答应
        }
        if(this.value==4){
          this.levelId=101//导师等级
        }
        this.leave()
        
      }
    },
    radio(){
      this.pageIndex=1
    },
  },
  created(){
    this.peopleType=this.$store.state.brandList
     let codes=cityList()//获取省市区数据
     this.citys2=this.citys=codes
     this.roleList=[...this.roleList,...this.$store.state.roleList]
     this.getlist()
     this.store()
     this.list()
     this.leave()
     
  },
  methods: {
    changeMentor(e){
     let list=this.teacherList.find(item=>{
        return item.userId==e
      })
      console.log(list)
      this.newMentorBrandId=list.brandId
      this.newMentorUserId=list.userId
    }, 
    creatQrCode(id) {//生成二维码 
    this.$nextTick(()=>{
      new QRCode(this.$refs['qrCodeUrl'], {
            text: 'https://sihuankangyuan.cn/iosPay.html?id='+id, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    })
         
    },
    selectBrand(e){
      this.brandId=e
      this.getlist()
    },
  async  getlist(){//获取导师列表
    const params={
      brandId:this.brandId
    }
     const {data}=await getTeacherList(params)
     if(data.code==200){
       this.teacherList=data.data
     }
     console.log(data.data)
    },
    
    async  getlist2(id){//获取导师列表
    const params={
      brandId:''
    }
     const {data}=await getTeacherList(params)
     if(data.code==200){
      this.teacherList=data.data
      let list=this.teacherList.find(item=>{
        return item.userId==id
      })
      console.log(list)
      this.newMentorBrandId=list.brandId
      this.newMentorUserId=list.userId
     }
    },
  downloadIamge(imgsrc, name) {//下载图片地址和图片名
  var image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function() {
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    var context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, image.width, image.height);
    var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
 
    var a = document.createElement("a"); // 生成一个a元素
    var event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name || "photo"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
},
downs(){
  let url='https://testuser-1308336073.cos.ap-nanjing.myqcloud.com/'+this.wxcode2
  this.downloadIamge(url, '二维码')
  },

   async list(){
      const params={
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        province:this.code[0],
        city:this.code[1],
        area:this.code[2],
        roleId:this.radio==3?3:this.radio,
        searchStr:this.input
      }
     const {data}=await pageroleList(params)
     console.log(data)
     if(data.code==200){
        this.tableData=data.data.records
        this.total=Number(data.data.total)
     }else{
       this.$message.error(data.msg)
     }
     
    },
   async setVip(row){
     const {data}=await setUserVip({userId:row.id})
     console.log(data)
      if(data.code==200){
              this.$message.success('操作成功')
             
            }else{
                this.$message.error(data.msg)
            }
    },
    details(row,isedit){//查看详情
    if(this.radio==3){
      this.getlist2(row.mentorUserId)
        this.isdisabled=true
        console.log(row)
       this.oldMentorUserId=this.parentUserId=row.mentorUserId
       this.oldMentorBrandId=row.brandId
       this.brandId=row.brandId
        var code2=[]
        this.isedit=isedit
        this.name=row.name,
        this.storeName=row.storeName
        this.value=row.roleId,
        this.levelId=row.levelId,
        this.storeId=row.storeId,
        this.sex=row.sex.toString(),
        this.birthday=row.birthday,
        this.phone=row.phone,
        this.cardNumber=row.cardNumber,
        code2[0]=row.province?row.province.toString():''
        code2[1]=row.city?row.city.toString():''
        code2[2]=row.area?row.area.toString():''
        this.code2=code2
        this.id=row.id
        this.dialogVisible=true
        if(row.roleId==4){//导师的话需要请求二维码
        this.$nextTick(()=>{
        document.getElementById("qrcode").innerHTML = "";
        })
        
        this.creatQrCode(row.id)
          getmentorInfo({userId:row.id}).then(res=>{
            console.log(res.data.data.mentorInfo.wxacodeUri)
            this.wxcode='https://sihuankangyuan.cn/'+res.data.data.mentorInfo.wxacodeUri
            this.wxcode2=res.data.data.mentorInfo.wxacodeUri
          })
        }
      
    }else{
      this.getlist()
        this.isdisabled=true
        console.log(row)
       this.oldMentorUserId=this.parentUserId=row.mentorUserId
       this.oldMentorBrandId=row.brandId
       this.brandId=row.brandId
        var code2=[]
        this.isedit=isedit
        this.name=row.name,
        this.storeName=row.storeName
        this.value=row.roleId,
        this.levelId=row.levelId,
        this.storeId=row.storeId,
        this.sex=row.sex.toString(),
        this.birthday=row.birthday,
        this.phone=row.phone,
        this.cardNumber=row.cardNumber,
        code2[0]=row.province?row.province.toString():''
        code2[1]=row.city?row.city.toString():''
        code2[2]=row.area?row.area.toString():''
        this.code2=code2
        this.id=row.id
        this.dialogVisible=true
        if(row.roleId==4){//导师的话需要请求二维码
        this.$nextTick(()=>{
        document.getElementById("qrcode").innerHTML = "";
        })
        
        this.creatQrCode(row.id)
          getmentorInfo({userId:row.id}).then(res=>{
            console.log(res.data.data.mentorInfo.wxacodeUri)
            this.wxcode='https://sihuankangyuan.cn/'+res.data.data.mentorInfo.wxacodeUri
            this.wxcode2=res.data.data.mentorInfo.wxacodeUri
          })
        }
      
    }
    
    // this.isdisabled=true
    //     console.log(row)
    //    this.oldMentorUserId=this.parentUserId=row.mentorUserId
    //    this.oldMentorBrandId=row.brandId
    //    this.brandId=row.brandId
    //     var code2=[]
    //     this.isedit=isedit
    //     this.name=row.name,
    //     this.storeName=row.storeName
    //     this.value=row.roleId,
    //     this.levelId=row.levelId,
    //     this.storeId=row.storeId,
    //     this.sex=row.sex.toString(),
    //     this.birthday=row.birthday,
    //     this.phone=row.phone,
    //     this.cardNumber=row.cardNumber,
    //     code2[0]=row.province?row.province.toString():''
    //     code2[1]=row.city?row.city.toString():''
    //     code2[2]=row.area?row.area.toString():''
    //     this.code2=code2
    //     this.id=row.id
    //     this.dialogVisible=true
    //     if(row.roleId==4){//导师的话需要请求二维码
    //     this.$nextTick(()=>{
    //     document.getElementById("qrcode").innerHTML = "";
    //     })
        
    //     this.creatQrCode(row.id)
    //       getmentorInfo({userId:row.id}).then(res=>{
    //         console.log(res.data.data.mentorInfo.wxacodeUri)
    //         this.wxcode='https://sihuankangyuan.cn/'+res.data.data.mentorInfo.wxacodeUri
    //         this.wxcode2=res.data.data.mentorInfo.wxacodeUri
    //       })
    //     }
    },
    add(isedit){
      console.log('我是添加人员')
      this.isedit=isedit
      this.name=''
      this.storeName=''
      this.value=''
      this.levelId='',
      this.storeId='',
      this.sex='1',
      this.birthday='',
      this.phone='',
      this.cardNumber='',
      this.code2[0]='',
      this.code2[1]='',
      this.code2[2]=''
      this.code2=[]
      this.id=''
      this.brandId='',//品牌id，新增导师专用
      this.parentUserId='',//上级导师id，新增导师专用
      this.parentUserId=''//推荐导师id，新增学员专用
      this.dialogVisible=true
      this.positionName=''
      this.wxcode=this.wxcode2=''
      this.isdisabled=false

    },
   async submit(){//提交
      const params={
        name:this.name,
        storeName:this.storeName,
        roleId:this.value,
        levelId:this.levelId,
        storeId:this.storeId,
        sex:this.sex,
        birthday:this.birthday,
        phone:this.phone,
        cardNumber:this.cardNumber,
        province:this.code2[0],
        city:this.code2[1],
        area:this.code2[2],
        id:this.id,
        brandId:this.brandId,//品牌id，新增导师专用
        positionName:this.positionName,//职位名称，新增导师专用
        parentUserId:this.parentUserId,//上级导师id，新增导师专用
        mentorUserId:this.parentUserId//推荐导师id，新增学员专用
      }
      // 
      if(this.value==14){//添加讲师，跟其他角色不同接口
      const {data}=await  saveTeacher({name:this.name})
        if(data.code==200){
              this.$message.success('操作成功')
              this.list()
              this.dialogVisible=false
            }else{
                this.$message.error(data.msg)
            }
      }else if(this.value==4){//添加导师，跟其他角色不同接口
      if(this.id){//编辑
           const {data}=await  updateMentor(params)
        if(data.code==200){
              this.$message.success('操作成功')
              this.list()
              this.dialogVisible=false
            }else{
                this.$message.error(data.msg)
            }
      }else{//添加
        const {data}=await  saveMentor(params)
        if(data.code==200){
              this.$message.success('操作成功')
              this.list()
              this.dialogVisible=false
            }else{
                this.$message.error(data)
            }
      }
      
      }else if(this.value==3){//添加学员，跟其他角色不同接口
      if(this.id){//编辑
      const params2={
        name:this.name,
        storeName:this.storeName,
        roleId:this.value,
        levelId:this.levelId,
        storeId:this.storeId,
        sex:this.sex,
        birthday:this.birthday,
        phone:this.phone,
        cardNumber:this.cardNumber,
        province:this.code2[0],
        city:this.code2[1],
        area:this.code2[2],
        id:this.id,
        newMentorBrandId:this.newMentorBrandId || this.oldMentorBrandId,
        newMentorUserId:this.newMentorUserId || this.oldMentorUserId,
        oldMentorBrandId:this.oldMentorBrandId,
        oldMentorUserId:this.oldMentorUserId
      }
           const {data}=await  updateTrainee(params2)
        if(data.code==200){
          
              this.$message.success('操作成功')
              this.list()
              this.dialogVisible=false
            }else{
                this.$message.error(data.msg)
            }
      }else{//添加
      const params2={
        name:this.name,
        storeName:this.storeName,
        roleId:this.value,
        levelId:this.levelId,
        storeId:this.storeId,
        sex:this.sex,
        birthday:this.birthday,
        phone:this.phone,
        cardNumber:this.cardNumber,
        province:this.code2[0],
        city:this.code2[1],
        area:this.code2[2],
        id:this.id,
        brandId:this.newMentorBrandId,//品牌id
        mentorUserId:this.newMentorUserId,//推荐导师id，新增学员专用
        vip:this.vip
      }
        const {data}=await  saveTrainee(params2)
        if(data.code==200){
              this.$message.success('操作成功')
              this.list()
              this.dialogVisible=false
            }else{
                this.$message.error(data.msg)
            }
      }
      
      }else{
         const {data}=await usersaveOrUpdate(params)
     if(data.code==200){
       this.$message.success('操作成功')
       this.list()
       this.dialogVisible=false
     }else{
        this.$message.error(data.msg)
     }
      }
    
    },
   async leave(){//获取等级列表
     const {data}=await levelList({roleId:this.value})
     if(data.code==200){
        // const obj={
        //    id:'',
        //    name:'全部'
        //  }
      //  this.levelList=[obj,...data.data]
       this.levelList=data.data
     }else{
       this.$message.error(data.msg)
     }
    },
     async store(){//获取门店列表
      const params={
        pageIndex:1,
        pageSize:9999
      }
     const {data}=await storepageList(params)
     if(data.code==200){
       console.log(data)
       this.storeList=[...this.storeList,...data.data.records]
     }else{
       this.$message.error(data.msg)
     }
    },
    handleClose() {
      this.dialogVisible = false;
    },
   
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=value
      this.list()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex=val
      this.list()
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-dialog__footer{
  text-align: center;
}
.inp /deep/.el-input__inner{
  border: none;
  font-size: 20px;
}
.inp2 /deep/.el-input__inner,/deep/.el-radio__label{
  // border: none;
  font-size: 20px;
}
.line {
  width: 9px;
  height: 22px;
  border-radius: 6px;
  background: #409eff;
  margin-right: 11px;
}
/deep/.el-dialog__body {
  padding: 30px 36px;
}
/deep/.el-radio-group span {
  // width: 106px;
  width: 72px;
}
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    align-items: center;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.ac2 /deep/.el-input__inner{
  margin-left: 10px;
}
.red {
  color: #ff1b0b;
}
/deep/.ml{
  margin-left: 10px;
}

</style>
